import React from "react";
import "./App.css";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Landing2 from "./pages/Landing/Landing2";
import TenantRegistration from "./pages/Tenant/Registration";
//
import NoPage from "./pages/NoPage/NoPage";
//

function App() {
  const notify = () => toast("Wow so easy!");

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing2 />}></Route>
          <Route path="/tenantreg" element={<TenantRegistration />}></Route>
          {/* <Route path="/login/:tenantId" element={<SignIn />}></Route> */}
          {/* <Route path="/" element={<SignIn />}></Route> */}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
