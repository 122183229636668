import React from "react";
import "./Landing2.css";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

// import discussImg from "../../assest/modules/discussion.gif";
// import dashboardImg from "../../assest/modules/dashboard.gif";
// import inventoryImg from "../../assest/modules/inventory.gif";
// import customersImg from "../../assest/modules/customer.gif";
// import suppliersImg from "../../assest/modules/ship.gif";
// import salesImg from "../../assest/modules/sales.gif";

const Landing2 = () => {
  const navigate = useNavigate();
  return (
    <div className="LandingMain">
      <header className="App-header">
        <div className="saashederTop">
          <div className="saasLogoDiv">HabSync</div>
          <div className="saasMenuActionDiv">
            <Button
              type="primary"
              onClick={() => {
                navigate("/tenantreg");
              }}
            >
              Try for Free1
            </Button>
          </div>
        </div>
      </header>

      <div className="HeroSection">
        <h1 className="Headline">
          Synchronize Your Business Operations with HabSync.
        </h1>
        <h2 className="Subheadline">
          Manage all your business needs in one place.
        </h2>
        <div className="CallForAction">
          <button>Get Started for free!</button>
        </div>
      </div>

      <div className="App_content">
        <div className="Modules">
          <div>
            <img
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/olkhzprmhcmlwjfvxmes"
              alt="Discuss"
            />
            <h2>Discuss</h2>
            <p>Engage in discussions and collaborate with your team.</p>
            <a href="">Learn More</a>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/nfvd1jvkv9xu727d5nlv"
              alt="Dashboard"
            />
            <h2>Dashboard</h2>
            <p>Visualize your company's data and key metrics.</p>
            <a href="">Learn More</a>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/i49yntbp3mmzzpt5rmri"
              alt="Inventory"
            />
            <h2>Inventory</h2>
            <p>Manage your inventory efficiently with our tools.</p>
            <a href="">Learn More</a>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/coryuyw5rskjzilptfq4"
              alt="Customers"
            />
            <h2>Customers</h2>
            <p>Track and manage your customer relationships.</p>
            <a href="">Learn More</a>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/w6ywjhlavrto5ulacxko"
              alt="Suppliers"
            />
            <h2>Suppliers</h2>
            <p>Manage your supplier relationships and orders.</p>
            <a href="">Learn More</a>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/ModulesIcon/rgbdwhard2gqcavx1tgl"
              alt="Sales"
            />
            <h2>Sales</h2>
            <p>Track sales activities and manage your pipeline.</p>
            <a href="">Learn More</a>
          </div>
        </div>
      </div>

      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} HabSync. All rights reserved.</p>
        <p>
          This site is under development. Some features may not be fully
          functional.
        </p>
      </footer>
    </div>
  );
};

export default Landing2;
